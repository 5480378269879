import './_body-view-height'

import './_menu.js';
import './_hero.js';
import './_scroll-to.js';

if (window.location.hash) {
    setTimeout(function () {
        $('html, body').scrollTop(0).show();
        $('html,body').animate({
            scrollTop: $(window.location.hash).offset().top - 150
        });
    }, 300)
}
import './_slideshow.js';
import './_header.js';
import './_forms.js';

window.$ = $;